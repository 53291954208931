<template>
  <div>
    <h3>Create new Runner Request</h3>
    <RunnerRequestForm></RunnerRequestForm>
  </div>
</template>

<script>
import RunnerRequestForm from "../../../components/Forms/RunnerRequestForm";

export default {
  name: "CreateRunnerRequest",
  components: {RunnerRequestForm},
};
</script>
